import React from 'react';
import Facebook from '../../../../assets/images/facebook.png';
import Youtube from '../../../../assets/images/youtube.png';
import Instagram from '../../../../assets/images/instagram.png';

const FooterBottom = () => (
    <div className="footer__bottom u-center-text">
        <div className="footer__bottom__image-box">
            <a href="/">
            <img src={Facebook} alt="" className="footer__bottom__image-box--img"/>
            </a>
            <a href="/">
                <img src={Youtube} alt="" className="footer__bottom__image-box--img" />
            </a>
            <a href="/">
                <img src={Instagram} alt="" className="footer__bottom__image-box--img" />
            </a>
        </div>
        <div className="footer__bottom__copyright paragraph--sm">
            ©2019 by Quick-Think
        </div>
    </div>
);

export default FooterBottom;