import React from 'react';
import './sass/main.scss';
import ReactDOM from 'react-dom';
import App from './App';
import { ProductProvider } from './context'

import Amplify from "aws-amplify";
import awsExports from './aws-exports';
Amplify.configure(awsExports);

ReactDOM.render(
  <ProductProvider>
    <App />
  </ProductProvider>, 
  document.getElementById('root')
);