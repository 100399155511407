import React from 'react'
import { Link } from 'react-router-dom';

export default function NonExist() {
    return (
        <div className="u-center-text">
            <h2 className="heading-primary grobold green">
                We're sorry this page does not exist
            </h2>
            <Link to="/" className="link u-underline">
                <span className="paragraph green">Back to homepage</span>
            </Link>
        </div>
    )
}
