import React from 'react';
import ShopProducts from './ShopProducts/ShopProducts';

const Shop = () => (
	<div className='shop'>
		<ShopProducts />
	</div>
);

export default Shop;
