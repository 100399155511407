import React from 'react';

// COMPONENT IMPORTS 
import AsSeenIn from './asSeenIn/AsSeenIn';
import Mission from './mission/Mission';
import Why from './why/Why';
import Contact from './contact/Contact';
import Carousel from './carousel/Carousel';
import HowItStarted from './howItStarted/HowItStarted';
import OurProducts from './ourProducts/OurProducts';

const Home = () => (
  <React.Fragment>
    <div className="home">
        <Carousel />
        <Mission />
        <Why />
        <OurProducts />
        <AsSeenIn />
        <HowItStarted />
        <Contact />
    </div>
  </React.Fragment>
);

export default Home;