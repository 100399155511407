import React from 'react';

const Mission = () => (
    <div className="mission u-center-text white">
        <h1 className="heading-primary grobold">
            our mission
        </h1>
        <div className="mission__separate"></div>
        <h2 className="heading-secondary">
            Make learning fun.
        </h2>
        <p className="paragraph">
            Bringing spark through competition.
        </p>
        <h2 className="heading-secondary">
            Make learning accessible. 
        </h2>
        <p className="paragraph">
            Anywhere, anyone, any time.
        </p>
        <h2 className="heading-secondary">
            Make offline learning stimulating.
        </h2>
        <p className="paragraph">
            Bringing people together without technology.
        </p>
    </div>
);

export default Mission;