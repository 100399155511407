import React from 'react'
import { Link } from 'react-router-dom';

export default function EmptyCart() {
    return (
        <div className="u-center-text">
            <h2 className="heading-primary grobold green">
                Your cart is currently empty
            </h2>
            <span className="paragraph">
                <Link to="/shop" className="green">
                    continue shopping
                </Link>
            </span>
        </div>
    )
}
