import React from 'react';
import { ProductConsumer } from '../../../../context';
import { Link } from 'react-router-dom';

const Product = props => {
	const {
		images,
		id,
		title,
		price
	} = props.product;
	return (
		<div className='product u-center-text'>
			<ProductConsumer>
				{value => (
					<React.Fragment>
						<Link to='/detail'>
							<div
								className='product__img-box'
								onClick={() => value.handleDetail(id)}
							>
								<img
									src={images[0]}
									alt='product'
									className='product__img-box--img'
								/>
								<div className='product__img-box--back'>
									<img
										src={images[1]}
										alt='product'
										className='product__img-box--img'
									/>
								</div>
							</div>
						</Link>
						<div className='product__text-box'>
							<span className='heading-tertiary black'>{title}</span>
							<div className='product__text-box__separator'></div>
							<span className='paragraph'>{price}</span>
						</div>
					</React.Fragment>
				)}
			</ProductConsumer>
		</div>
	);
};

export default Product;
