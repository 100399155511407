import React, { Component } from 'react';
import { PayPalButton } from 'react-paypal-button-v2';
import axios from 'axios';
export default class PayPalButtonV2 extends Component {
	render() {
		const { total, clearCart } = this.props;
		return (
			<PayPalButton
				amount={total}
				onSuccess={(details, data) => {
					const orderInfo = {
						data: data,
						details: details,
						orderSummary: this.props.cart
					};
                    console.log(orderInfo)
					return axios
						    .post('/paypal-transaction-complete', orderInfo)
						    .then(res => {
                                clearCart();
						    })
						    .catch(err => {
                                    
						    });
				}}
				options={{
					clientId:
						'ATKfV0ZpYqAWPKNeO6xi7YyIKHxu_YjFDq3pEKMhJu47mdBH2j3Jy3ow8x6xfeDLJiwdGsyxx6XU_s3r'
				}}
			/>
		);
	}
}
