import React from 'react';

const Support = () => (
	<div className='support'>
		<h2 className='heading-secondary--lg grobold green u-margin-top-sm u-center-text'>Frequently asked questions</h2>
		<div className='support__text-box'>
			{/* FAQ SECTION 1 */}
			<span className='faq-header'>Order</span>
            <p className='paragraph paragraph--bold u-margin-top-sm'>
				Where can buy Quick-Think games?
			</p>
            <p className='paragraph--sm grey'>
				Go to our online store and buy as many games as your heart desires :).
			</p>
            <p className="paragraph--sm grey">
                We sell our games $11.99 for any one deck + shipping.
            </p>
            <p className='paragraph paragraph--bold u-margin-top-sm'>
				Can I get a discount for bulk order?
			</p>
            <p className='paragraph--sm grey'>
				If you buy three or more decks, then shipping is FREE.
			</p>
            <p className="paragraph--sm u-margin-bottom-sm grey">
                If you are an
				educator, please visit our FAQ category for educators to get a larger
				discount on bulk orders.
            </p>
			{/* FAQ SECTION 2 */}
			<span className='faq-header'>Shipping</span>
            <p className='paragraph paragraph--bold u-margin-top-sm'>Where do you ship?</p>
            <p className='paragraph--sm grey'>
				Right now, we only ship in the United States. We have a flat rate for
				shipping.
			</p>
            <p className='paragraph paragraph--bold u-margin-top-sm'>
				When should I expect my order to be delivered?
			</p>
            <p className='paragraph--sm u-margin-bottom-sm grey'>
				Please expect your order to be delivered 7-9 business days after you
				place your order. If your order is hasn't arrived by then, please fill
				out our contact form and we will get to you as soon as we can.
			</p>
			{/* FAQ SECTION 3 */}
			<span className='faq-header'>Returns</span>
            <p className='paragraph paragraph--bold u-margin-top-sm'>What is your return policy?</p>
            <p className='paragraph--sm u-margin-bottom-sm grey'>
				If you are unhappy, just return within 30 days for a full refund. In
				order to do so, please fill out our contact form and we will get to you
				as soon as possible.
			</p>
			{/* FAQ SECTION 4 */}
			<span className='faq-header'>For Educators</span>
            <p className='paragraph paragraph--bold u-margin-top-sm'>
				I want to buy a bulk order of your games for my school. How can I go
				about doing this?
			</p>
            <p className='paragraph--sm grey u-margin-bottom-sm'>
				Please contact us through our contact form, and we will be able to
				coordinate and accommodate to your needs.
			</p>
		</div>
	</div>
);

export default Support;
