import React, { Component } from 'react';
import axios from 'axios';


class FooterContact extends Component {

	state = {
		name: '',
		email: '',
		error: false
	}

	inputHandler = e => {
		
		let name = e.target.name;
		let value = e.target.value;

		this.setState(prevState => {

			return {
				...this.state,
				[name]: value
			}
		})
	}

	submitHandler = () => {
		let notify = {
			name: this.state.name,
			email: this.state.email
		};

		axios.post('/notify', notify)
			.then(res => {
				this.setState({
					name: '',
					email: '', 
					error: false
				})
			})
			.catch(err => {
				this.setState({error: true})
			})
	}

	render() {
		return (
			<div className='footer__contact u-center-text'>
				<p className='paragraph'>Get Notified About Future Products!</p>
				<input
					type='text'
					name='name'
					value={this.state.name}
					onChange={this.inputHandler}
					placeholder='Full Name'
					className='input--footer'
				/>
				<input
					type='text'
					name='email'
					value={this.state.email}
					onChange={this.inputHandler}
					placeholder='Email Address'
					className='input--footer'
				/>
                <span className="btn btn--lg" onClick={this.submitHandler}>
                    Keep me updated!
                </span>
				{this.state.error && <span className="paragraph--sm red">There was an error please try again</span>}
			</div>
		);
	}
}

export default FooterContact;
