import React, { Component } from 'react';
import Layout from './components/layout/Layout';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';


// ALL PAGES
import Home from './components/pages/home/Home';
import HowToPlay from './components/pages/howToPlay/HowToPlay';
import Support from './components/pages/support/Support';
import About from './components/pages/about/About';
import Shop from './components/pages/shop/Shop';
import Cart from './components/pages/cart/Cart';
import Detail from './components/pages/shop/detail/Detail';
import NonExist from './components/pages/404/NonExist';
import Blog from './components/pages/blog/Blog';

// TODO: 
// - search bar on support page.
// - put linked in for the about page.
// - change stock photos to actual photos.
// - contact boxes should clear after successful send
// - when details page is refreshed there is a error
// - consider saving detail product to local storage

class App extends Component {

  render(){
    return (
      <React.Fragment>
        <Router>
          <Layout>
            <Switch>
              <Route exact path='/' component={Home} />
              <Route path='/how-to-play' component={HowToPlay} />
              <Route path='/support' component={Support} />
              <Route path='/about' component={About} />
              <Route path='/shop' component={Shop} />
              <Route path='/cart' component={Cart} />
              <Route path='/detail' component={Detail} />
              <Route path='/blog' component={Blog} />
              <Route path='*' component={NonExist} />
            </Switch>
          </Layout>
        </Router>
      </React.Fragment>
    )
  }
}

export default App;
