import React from 'react';

const Member = props => (
	<div className='member u-center-text'>
		<div className='member__img-box'>
			<div className='member__img-box--description'>
				<div className='member__img-box--description__text-box'>
					<span className='heading-tertiary grobold green-dark'>
						{props.title}
					</span>
					<div className='member__separate'></div>
					<p className='paragraph'>{props.description}</p>
				</div>
			</div>

			<img src={props.img} className='member__img-box--img' alt='team member' />
		</div>
	</div>
);

export default Member;
