import React from 'react';
import Navbar from './navbar/Navbar';
import Footer from './footer/Footer';
import NavbarMobile from './navbar/NavbarMobile';


const Layout = props => (
    <React.Fragment>
        <NavbarMobile />
        <Navbar />
        {props.children}
        <Footer />
    </React.Fragment>
);

export default Layout;