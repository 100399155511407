import React from 'react';
import ACBF from '../../../../assets/images/acbf.png';
import Fun from '../../../../assets/images/fundamentally-toys.png';

const AsSeenIn = () => (
	<div className='as-seen-in u-center-text'>
		<div className='as-seen-in__text-box'>
			<span className='heading-tertiary green grobold'>As Seen In...</span>
		</div>

		<div className='as-seen-in__img-box'>
			<img
				src={ACBF}
				alt="action children's business fair"
				className='as-seen-in__img-box--img'
			/>
			<img
				src={Fun}
				alt='fundamentally toys'
				className='as-seen-in__img-box--img'
			/>
		</div>
	</div>
);

export default AsSeenIn;
