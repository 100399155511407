import React, { Component } from 'react';
import { Products } from './data';
import ls from 'local-storage';


const ProductContext = React.createContext();


// ADD shipping to subtotal
class ProductProvider extends Component {
	state = {
		products: [],
		detailProduct: '',
		cart: [],
		imageIndex: 0,
		cartTotal: 0,
		cartTax: 0,
		cartSubTotal: 0,
		ipLocation: null,
		locationError: false
	};

	// this will ensure we are not manipulating data directly
	setProducts = () => {
		let products = [];
		Products.forEach(product => {
			// to destruct the nested objects
			const singleProduct = { ...product };
			products = [...products, singleProduct];
		});
		this.setState(() => {
			return { products };
		});
	};


	// when a product is clicked in product.js this will return the correct item.
	// this function is used in handleDetail
	getItem = id => {
		const product = this.state.products.find(item => item.id === id);
		return product;
	};


	// this sets the detailProduct object in the state to the product object that was clicked
	handleDetail = id => {
		const product = this.getItem(id);
		ls.set('detailProduct', product)
		this.setState({ detailProduct: product });
	};

	// when a user clicks "add to cart" on the details page it will then add that item to the state
	// and them to the cart array.
	addToCart = id => {
		let tempProducts = [...this.state.products]; // make a copy of products data
		const index = tempProducts.indexOf(this.getItem(id));
		const product = tempProducts[index];
		product.inCart = true;
		product.count = 1;
		const price = product.price;
		product.total = price;
		this.setState(() => {
			return { products: tempProducts, cart: [...this.state.cart, product] };
		}, 
			() => {
				this.addTotals();
			}
		);
	};


	// this handles when a user clicks a thumbnail on the details page and changes
	// between the pictures of that item.
	handleThumbnail = e => {
		let i = +e.target.name;
		this.setState({ imageIndex: i });
	};


	componentDidMount = () => {
		this.setProducts();
		this.setState({
			detailProduct: ls.get('detailProduct') || ''
		});
	};

	addTotals = () => {
		let subTotal = 0;
		this.state.cart.map(item => {
			return subTotal += item.total;
		})
		// const tempTax = subTotal * 0.0625;
		// const tax = parseFloat(tempTax.toFixed(2));

		// ADD shipping to subtotal
		const total = subTotal + 3.98;
		this.setState(() => {
			return {
				cartSubTotal: subTotal,
				//cartTax: tax,
				cartTotal: total
			}
		})
	}

	incrementHandler = (id) => {
		let tempCart = [...this.state.cart];
		const selectedProduct = tempCart.find(item => {
			return item.id === id
		});
		const index = tempCart.indexOf(selectedProduct);
		const product = tempCart[index];

		product.count = product.count + 1;
		product.total = product.count * product.price;

		this.setState(() => { return { cart: [...tempCart] } }, () => { this.addTotals(); })
	}

	decrementHandler = id => {
		let tempCart = [...this.state.cart];
		const selectedProduct = tempCart.find(item => {
			return item.id === id
		});
		const index = tempCart.indexOf(selectedProduct);
		const product = tempCart[index];

		product.count = product.count - 1;
		if (product.count === 0) {
			this.removeItem(id);
		}
		else {
			product.total = product.count * product.price;
			this.setState(() => { return { cart: [...tempCart] } }, () => { this.addTotals(); })
		}

	}

	removeItem = id => {
		let tempProducts = [...this.state.products];
		let tempCart = [...this.state.cart];

		tempCart = tempCart.filter(item => {
			return item.id !== id
		});

		const index = tempProducts.indexOf(this.getItem(id))
		let removedProduct = tempProducts[index];
		removedProduct.inCart = false;
		removedProduct.count = 0;
		removedProduct.total = 0;

		this.setState(() => {
			return {
				cart: [...tempCart],
				products: [...tempProducts]
			}
		},
			() => {
				this.addTotals();
			}
		)
	}

	clearCart = () => {
		this.setState(() => {
			return { cart: [], }
		}, () => {
			// resets a new original copy of the products 
			// we need this because when you click add to cart you 
			// are modifying the values of that object { inCart : true }
			this.setProducts();
			this.addTotals();
		})
	}

	render() {
		return (
			<ProductContext.Provider
				value={{
					...this.state,
					handleDetail: this.handleDetail,
					addToCart: this.addToCart,
					handleThumbnail: this.handleThumbnail,
					incrementHandler :this.incrementHandler,
					decrementHandler: this.decrementHandler,
					removeItem: this.removeItem,
					clearCart: this.clearCart,
					getLocation: this.getLocation
				}}
			>
				{this.props.children}
			</ProductContext.Provider>
		);
	}
}

const ProductConsumer = ProductContext.Consumer;

export { ProductConsumer, ProductProvider };
