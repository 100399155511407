import React from 'react';
import { ProductConsumer } from '../../../../context';
import Product from '../product/Product';

class ShopProducts extends React.Component {
	render() {
		return (
			<React.Fragment>
				<h1 className='heading-primary green grobold u-center-text'>Our Products</h1>
				<div className='shop-products u-center-text'>
					<ProductConsumer>
						{value => {
							return value.products.map(product => {
								return <Product key={product.id} product={product} />;
							});
						}}
					</ProductConsumer>
				</div>
			</React.Fragment>
		);
	}
}

export default ShopProducts;
