import React from 'react';
import FooterBottom from './footerComponents/FooterBottom';
import FooterContact from './footerComponents/FooterContact';
import Owl from '../../../assets/images/did-I-miss-it.gif'

const Footer = () => (
    <footer>
        <hr />
        <div className="footer">
            <FooterContact />
            <div className="footer__gif-box">
                <img className="footer__gif-box--img" src={Owl} alt="Owl gif"/>
            </div>
            <FooterBottom />
        </div>
    </footer>
);

export default Footer;