import React, { Component } from 'react';
import axios from 'axios';

class Contact extends Component {
	state = {
		first: '',
		last: '',
		phone: '',
		email: '',
		message: '',
		error: false
	};

	inputHandler = e => {
		let name = e.target.name;
		let value = e.target.value;

		this.setState(prevState => {
			return {
				...prevState,
				[name]: value
			};
		});
	};

	submitHandler = () => {
		let contact = {
			fname: this.state.first,
			lname: this.state.last,
			phone: this.state.phone,
			email: this.state.email,
			message: this.state.message
		};

		axios
			.post('/contact', contact)
			.then(res => {
				this.setState({
					first: '',
					last: '',
					phone: '',
					email: '',
					message: '',
					error: false
				});
			})
			.catch(err => {
				this.setState({ error: true });
			});
	};

	render() {
		return (
			<div className='contact'>
				<h3 className='heading-tertiary'>Contact Us</h3>
				<div className='contact__form-box'>
					<div className='contact__form-box--input'>
						<input
							onChange={this.inputHandler}
							type='text'
							className='input'
							name='first'
							placeholder='First Name'
							value={this.state.first}
						/>
						<input
							onChange={this.inputHandler}
							type='text'
							className='input'
							name='last'
							value={this.state.last}
							placeholder='Last Name'
						/>
						<input
							onChange={this.inputHandler}
							type='text'
							className='input'
							value={this.state.email}
							name='email'
							placeholder='Email'
						/>
						<input
							onChange={this.inputHandler}
							type='text'
							className='input'
							name='phone'
							placeholder='Phone'
							value={this.state.phone}
						/>
						<textarea
							name='message'
							onChange={this.inputHandler}
							placeholder='Type your message here'
							cols='30'
							rows='10'
							className='input input--text-area'
							value={this.state.message}
						></textarea>
						<span
							className='btn btn--light btn--sm contact__form-box--input--btn'
							onClick={this.submitHandler}
						>
							Submit
						</span>
						{this.state.error && (
							<span className='paragraph--sm red'>
								There was an error please try again
							</span>
						)}
					</div>
				</div>
			</div>
		);
	}
}

export default Contact;
