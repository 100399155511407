import React from 'react'

export default function CartColumns() {
    return (
        <React.Fragment>
        <div className="cart-columns">
            <div className="cart-columns__column">
                <span className="paragraph">Price</span>
            </div>
            <div className="cart-columns__column">
                <span className="paragraph">Qty</span>
            </div>
            <div className="cart-columns__column">
                <span className="paragraph">Total</span>
            </div>
        </div>
        </React.Fragment>
    )
}
