import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../../assets/images/navbar-img.png';
//import CartIcon from '../../pages/cart/cart-components/CartIcon';
import { ProductConsumer } from '../../../context';


const Navbar = () => (
    <nav>
        <div className="navbar">
            <div className="navbar__logo">
                <img src={Logo} alt="Quick Think logo" className="navbar__logo--img"/>
            </div>
            <div className="navbar__header">
                <h2 className="heading-secondary grobold">
                    Quick-Think
                </h2>
            </div>

            <div className="navbar__list-box navbar__desktop">
                <ul className="navbar__list">
                    <li className="navbar__item">
                        <NavLink className="navbar__link" exact to="/">
                            Home
                        </NavLink>
                    </li>
                    <li className="navbar__item">
                        <NavLink className="navbar__link" to="/shop">
                            Shop
                        </NavLink>
                    </li>
                    <li className="navbar__item">
                        <NavLink className="navbar__link" to="/how-to-play">
                            How to Play
                        </NavLink>
                    </li>
                    <li className="navbar__item">
                        <NavLink className="navbar__link" to="/blog">
                            Blog
                        </NavLink>
                    </li>
                    <li className="navbar__item">
                        <NavLink className="navbar__link" to="/support">
                            Support
                        </NavLink>
                    </li>
                    <li className="navbar__item">
                        <NavLink className="navbar__link" to="/about">
                            About
                        </NavLink>
                    </li>
                    <li className="navbar__item">
                        <ProductConsumer>
                            {value => {
                                if (value.cart.length > 0) {
                                    return (
                                        <NavLink to="/cart" className="navbar__link">
                                            Cart ({value.cart.length})
                                        </NavLink>
                                    )
                                }
                                else {
                                    return null;
                                }
                            }}
                        </ProductConsumer>  
                    </li>
                </ul>
                {/* OLD CART HANDLER */}
                {/* <ProductConsumer>
                    {value => {
                       if(value.cart.length > 0){
                           return (
                               <Link to="/cart" className="link">
                                    <CartIcon count={value.cart.length}/>
                               </Link>
                                )
                       }
                       else {
                           return null;
                       }
                    }}
                </ProductConsumer>     */}
            </div>
        </div>
        
    </nav>
);

export default Navbar;