import React from 'react';

const HowToPlay = () => (
	<div className='how-to-play u-center-text'>
        <h2 className="heading-primary grobold green">
            How to Play!
        </h2>
		<iframe
			title='how to play'
			width='560'
			height='315'
			src='https://www.youtube.com/embed/YNKlIH9MXlw'
			frameBorder='0'
			allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
			allowFullScreen
		></iframe>
        <p className="paragraph">
            Fractions and Languages coming soon!
        </p>
	</div>
);

export default HowToPlay;
