import CountriesFront from './assets/images/countries-front.png'
import CountriesBack from './assets/images/countries-back.png'
import NumbersFront from './assets/images/numbers-front.png'
import NumbersBack from './assets/images/numbers-back.png'
import WordsFront from './assets/images/words-front.png'
import WordsBack from './assets/images/words-back.png'


export const Products = [
	{
		id: 1,
		title: 'Numbers',
        images: [NumbersFront, NumbersBack ],
		price: 11.99,
		info: `Numbers is a colorful card game designed to teach math facts in a fun and competitive way. There are 31 cards and instructions in the deck. Numbers will quiz you on addition, subtraction, multiplication, and division and help you practice your mental math skills.There is ONE and only ONE match between any two cards. There are hundreds of combinations, so every game you play will be a new challenge. It's a fun game to play at home, on-the-go, or at school. Rekindle your love of math with Numbers!`,
		instructions: ` 1. One or more players can play this game. Draw ANY two cards and place them face up for all the players to see.
                        2. Try and find two equations that match in value. For example, 4 + 2 and 10 - 4 match, as they are both equal to 6. There is only one match for any pair of cards.
                        3. The first player to call out the matching value gets to keep the two cards. Keep playing! The player who gets the most matches wins!`,
		inCart: false,
		count: 0,
        total: 0,
        refund: `If you are unsatisfied with our product, you can return the game within 30 days of your purchase for a full refund. For return information, please fill out our contact form and we'll get to you as soon as possible.`
	},
    {
        id: 2,
        title: 'Words',
        images: [WordsFront, WordsBack],
        price: 11.99,
        info: `Words is a colorful card game designed to teach new vocabulary in a fun and competitive way. There are 31 cards, instructions, and a key in the deck. There is ONE and only ONE match between any two cards. There are hundreds of combinations, so every game you play will be a new challenge. It's a fun game to play at home, on-the-go, or at school. Rekindle your love of vocabulary with Words!`,
        instructions: ` 1. One or more players can play this game. Draw ANY two cards and place them face up for all the players to see.
                        2. Try and find the two words that are most similar in meaning. For example, MOIST and SOGGY match, because they both mean WET. There is only one match for any pair of cards.
                        3. The first player to call out the word pair gets to keep the two cards. Keep playing! The player who gets the most matches wins!`,
        inCart: false,
        count: 0,
        total: 0,
        refund: `If you are unsatisfied with our product, you can return the game within 30 days of your purchase for a full refund. For return information, please fill out our contact form and we'll get to you as soon as possible.`
    },
    {
        id: 3,
        title: 'Countries',
        images: [CountriesFront, CountriesBack],
        price: 11.99,
        info: `Countries is a colorful card game designed to help you learn about countries around the world in a fun and competitive way. There are 31 cards, instructions, and a key in the deck. There is ONE and only ONE match between any two cards. There are hundreds of combinations, so every game you play will be a new challenge. It's a fun game to play at home, on-the-go, or at school. Rekindle your love of geography with Countries!`,
        instructions: ` 1. One or more players can play this game. Draw ANY two cards and place them faceup for all the players to see.
                        2. Match the items (flags, people, rivers, etc.) on the cards by their country. For example, Cairo and King Tut match, as they are both from Egypt. There is only ONE match for any two cards.
                        3. The first player to call out the match gets to keep the two cards. Keep playing! The player who gets the most matches wins!`,
        inCart: false,
        count: 0,
        total: 0,
        refund: `If you are unsatisfied with our product, you can return the game within 30 days of your purchase for a full refund. For return information, please fill out our contact form and we'll get to you as soon as possible.`
    }
];
