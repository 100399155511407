import React, { Component } from 'react';
import { ProductConsumer } from '../../../../context';
import { Link } from 'react-router-dom';
import ls from 'local-storage';
import BreadCrumb from '../breadcrumbs/BreadCrumb';
class Detail extends Component {
	render() {
		return (
			<ProductConsumer>
				{value => {
					const {
						id,
						images,
						info,
						refund,
						instructions,
						price,
						title,
						inCart
					} = value.detailProduct || ls.get('detailProduct');
					// ls.get('detailProduct') ensures that on page refresh the website still has an product
					return (
						<React.Fragment>
							<BreadCrumb product={title}/>
							<div className='detail'>
								{/* left side of the detail page */}
								<div className='detail__img-side'>
									<div className='detail__img-side__img-box'>
										<img
											src={images[value.imageIndex]}
											className='detail__img-side__img-box--img'
											alt='product'
										/>
									</div>
									<div className='detail__img-side__thumbnail'>
										{images.map((image, index) => {
											return (
												<img
													src={images[index]}
													name={index}
													onClick={value.handleThumbnail}
													key={index}
													alt='product thumbnail'
													className={
														value.imageIndex === index
															? 'detail__img-side__thumbnail--img detail__img-side__thumbnail--img__active-thumbnail'
															: 'detail__img-side__thumbnail--img'
													}
												/>
											);
										})}
									</div>
									<div className='detail__img-side__text-box'>
										<p className='paragraph'>{info}</p>
									</div>
								</div>
								{/* right side of the detail page */}
								<div className='detail__cart-side'>
									<h1 className='heading-primary black grobold'>{title}</h1>
									<span className='paragraph black'>${price}</span>
									{/* <div className='detail__cart-side__quantity'>
									<span>Quantity</span>
								</div> */}
									<span
										onClick={() => value.addToCart(id)}
										className='btn btn--add-to-cart u-center-text'
									>
										{inCart ? 'In Cart ✓' : 'Add to cart'}
									</span>
									<span className='green paragraph detail__cart-side--mobile-link'>
										<Link to='/cart' className='link u-underline'>
											Go to cart
										</Link>
									</span>
									<div className='detail__cart-side__instructions-box u-margin-bottom-md'>
										<span className='u-margin-bottom-sm u-margin-top-sm u-block-el'>
											INSTRUCTIONS
										</span>
										<p className='paragraph--sm u-margin-bottom-md'>
											{instructions}
										</p>
									</div>
									<hr />
									<div className='detail__cart-side__refund-box'>
										<span className='u-margin-bottom-sm u-margin-top-sm u-block-el'>
											RETURN & REFUND POLICY
										</span>
										<p className='paragraph--sm u-margin-bottom-md'>{refund}</p>
									</div>
								</div>
							</div>
						</React.Fragment>
					);
				}}
			</ProductConsumer>
		);
	}
}
export default Detail;
