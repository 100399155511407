import React from 'react';
import WCard from './WCard';


const Why = () => (
    <div className="why">
        <WCard backgroundColor="wcard--black" color="white" header="What?" paragraph=" A pocket-full of cards with the power to challenge your mind"/>
        <WCard backgroundColor="wcard--grey" color="green-light" header="Who?" paragraph="Anyone who loves learning and playing games!" />
        <WCard backgroundColor="wcard--grey-light" color="green" header="Where?" paragraph="At home, in the classroom, or on the go!" />
        <WCard backgroundColor="wcard--green" color="black" header="Why?" paragraph="Learning can be fun and doesn't have to be on the phone." />
    </div>
);

export default Why;