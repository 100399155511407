// this is a hard coded blog for now.
import React from 'react';

// images
import HowWeGotStarted from '../../../assets/images/how-we-got-started.png';
import hwgs1 from '../../../assets/images/hwgs1.png';
import hwgs2 from '../../../assets/images/hwgs2.png';
import hwgs3 from '../../../assets/images/hwgs3.png';
import hwgs4 from '../../../assets/images/hwgs4.png';
import hwgs5 from '../../../assets/images/hwgs5.png';
import hwgs6 from '../../../assets/images/hwgs6.png';


export default function Blog() {
	return (
		<div className='blog'>
			<div className='blog__post u-center-text'>
				<h2 className='heading-primary black grobold'>How we got started</h2>
				<p className='paragraph'>
					As with all startups, Quick-Think is no stranger to old, embarrassing
					photos of the early stages of development. For my family and I, when
					we see these pictures, it's like looking at baby pictures of our
					child. We sit around our kitchen table, with steaming cups of chai in
					our hands, laughing at and cherishing the incredible journey we have
					been on. But this journey includes you, our customers and fans. So we
					wanted to share Quick-Think's baby pictures with you all. Enjoy!
				</p>

				<img src={HowWeGotStarted} alt='how we got started' className="blog__post--img" />
				<span className='paragraph--sm u-margin-bottom-md'>
					Some of our initial designs for our cards! We experimented with fonts,
					possible logos, and backgrounds.
				</span>

                <img src={hwgs1} alt='original logo' className="blog__post--img" />
				<span className='paragraph--sm u-margin-bottom-md'>
					Anika, my co-founder and little sister, drew these turtles as a
					baseline for our logo!
				</span>

                <img src={hwgs2} alt='mentoring' className="blog__post--img" />
				<span className='paragraph--sm u-margin-bottom-md'>
					Quick-Think gains some valuable advice from mentors at Austin's
					incubator, Capital Factory
				</span>

                <img src={hwgs3} alt='prototypes' className="blog__post--img"/>
				<span className='paragraph--sm u-margin-bottom-md'>
					Hand-cutting our initial prototypes to test in the kitchen
				</span>

                <img src={hwgs4} alt='working with students' className="blog__post--img"/>
				<span className='paragraph--sm u-margin-bottom-md'>
					I went to several local elementary schools to gain some constructive
					criticism from potential customers
				</span>

                <img src={hwgs5} alt='winning most innovative business award' className="blog__post--img" />
				<span className='paragraph--sm u-margin-bottom-md'>
					Anika and I opening up our booth at the Acton Children's Business Fair
					in Austin, Texas on October 27, 2018! We were so grateful and honored
					to win the Most Innovative Business Award at the Fair.
				</span>

                <img src={hwgs6} alt='professionally ready games' className="blog__post--img" />
				<span className='paragraph--sm u-margin-bottom-md'>
					When we got our shipment of professionally printed games, we started
					to package them!
				</span>

				<p className='paragraph'>
					And so there you have it! A few snapshots of our journey to this
					point, and we can't wait for the rest of the journey!
				</p>
			</div>
		</div>
	);
}
