import React from 'react';

const WCard = props => (
    <div className={'wcard u-center-text ' + props.backgroundColor}>
        <h3 className={'heading-tertiary grobold ' + props.color}>
            {props.header}
        </h3>
        <p className="paragraph">
            {props.paragraph}
        </p>
    </div>
);

export default WCard;