import React from 'react';
import { Link } from 'react-router-dom';

const BreadCrumb = props => (
	<React.Fragment>
        <div className="breadcrumb">
            <div className="breadcrumb__link paragraph grey">
                <Link to='/' className="link grey">Home</Link> &nbsp; /
            </div>
            <div className="breadcrumb__link paragraph grey">
                <Link to='/shop' className="link grey">Shop</Link> &nbsp; /
            </div>
            <div className="breadcrumb__link paragraph green">
                {props.product}
            </div>
        </div>
	</React.Fragment>
);

export default BreadCrumb;
