import React, { Component } from 'react';
import { ProductConsumer } from '../../../context';
import EmptyCart from './cart-components/EmptyCart';
import CartColumns from './cart-components/CartColumns';
import CartList from './cart-components/CartList';
import CartTotal from './cart-components/CartTotals';


export default class Cart extends Component {
	render() {
		return (
			<div className='cart'>
				<ProductConsumer>
					{value => {
						const { cart } = value;
						if (cart.length > 0) {
							return (
								<React.Fragment>
									<h1 className='grobold heading-primary green u-center-text'>
										Your Cart
									</h1>
									<CartColumns />
									<CartList value={value} />
									<CartTotal value={value} history={this.props.history} />
								</React.Fragment>
							);
						} else {
							return <EmptyCart />;
						}
					}}
				</ProductConsumer>
			</div>
		);
	}
}
