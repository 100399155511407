import React, { Component } from 'react';
import QT_kids from '../../../../assets/images/QT-kids-hires.png';
import testing from '../../../../assets/images/testing_in_elementary_school.png';
import capital_factory from '../../../../assets/images/capital_factory.png';

class Carousel extends Component {
	state = {
		imgs: [
			{ img: QT_kids, index: 0 },
			{ img: testing, index: 1 },
			{ img: capital_factory, index: 2 }
		],
		index: 0
	};

	// when somebody clicks on a dot it will change the
	// index to the pictures correlating index
	dotClickHandler = e => {
        let name = +e.target.name;      // has to be converted to a number in order for the class 
        this.setState({ index: name }); // to be set dynamically 
	};

	// this function gets the number of the current index
	// and adds 1 to it if its not greater than imgs length
	indexControl = () => {
		let index = this.state.index;
		let length = this.state.imgs.length - 1;

		index >= length ? (index = 0) : index++;
		this.setState({ index });
	};

	// on mount this invokes the picture changing in a interval
	componentDidMount = () => {
		this.indexChange = setInterval(() => {
			this.indexControl();
		}, 3500);
	};

	// stop the interval when someone clicks out of this page
	componentWillUnmount = () => {
		clearInterval(this.indexChange);
	};

	render() {
		return (
			<div className='carousel'>
				<img
					src={this.state.imgs[this.state.index].img}
					className='carousel--img'
					alt='carousel'
				/>
				<div className="carousel__header u-center-text">
					<span className="heading-secondary--lg grobold white heading-secondary--mobile">Inspiring Learning and Curiosity!</span>
				</div>
				<div className='carousel__dot-display'> 
                    {/* set the class to either contain the .active class or not */}
					<button
						className={
							this.state.index === 0
								? 'carousel__dot-display--dot carousel__dot-display--dot--active'
								: 'carousel__dot-display--dot'
						}
						onClick={this.dotClickHandler}
						name='0'
					></button>
					<button
						className={
							this.state.index === 1
								? 'carousel__dot-display--dot carousel__dot-display--dot--active'
								: 'carousel__dot-display--dot'
						}
						onClick={this.dotClickHandler}
						name='1'
					></button>
					<button
						className={
							this.state.index === 2
								? 'carousel__dot-display--dot carousel__dot-display--dot--active'
								: 'carousel__dot-display--dot'
						}
						onClick={this.dotClickHandler}
						name='2'
					></button>
				</div>
			</div>
		);
	}
}

export default Carousel;