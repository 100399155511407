import React from 'react';
import Member from './memberComponent/Member';


import anaya from '../../../assets/images/anaya_face.png';
import anika from '../../../assets/images/anika_face.png';


const About = () => (
	<div className='about'>
		<h2 className='heading-secondary--lg grobold green u-center-text'>Our Team</h2>
		<div className='about__members'>
			<Member
				img={anaya}
				title='Chief Excitement Officer'
				description="Hello! My name is Anaya and I am 17 years old.
                If I could describe myself in 3 words, I would say I'm 
                curious, funny, and enthusiastic. My favorite things 
                to do include working on Quick-Think, playing chess,
                and hanging out with my friends. My favorite QT game 
                is Countries."
			/>
			<Member
				img={anika}
				title='Chief Imagination Officer'
				description='Hi, everyone! My name is Anika and I am
                9 years old. In 3 words, I would describe myself as 
                cheerful, smart, and kind. My favorite animal is an 
                elephant. My favorite QT game is Numbers.'
			/>
		</div>
	</div>
);

export default About;
