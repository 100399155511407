import React from 'react';
import { NavLink } from 'react-router-dom';
import { ProductConsumer } from '../../../context';

const NavbarMobileList = props => (
	<div className='navbar-mobile__list-box'>
		<ul className='navbar-mobile__list'>
			<li className='navbar-mobile__item' onClick={props.btnClicked}>
				<NavLink className='navbar-mobile__link' exact to='/'>
					Home
				</NavLink>
			</li>
			<li className='navbar-mobile__item' onClick={props.btnClicked}>
				<NavLink className='navbar-mobile__link' to='/shop'>
					Shop
				</NavLink>
			</li>
			<li className='navbar-mobile__item' onClick={props.btnClicked}>
				<NavLink className='navbar-mobile__link' to='how-to-play'>
					How to Play
				</NavLink>
			</li>
			<li className='navbar-mobile__item' onClick={props.btnClicked}>
				<NavLink className='navbar-mobile__link' to='blog'>
					Blog
				</NavLink>
			</li>
			<li className='navbar-mobile__item' onClick={props.btnClicked}>
				<NavLink className='navbar-mobile__link' to='support'>
					Support
				</NavLink>
			</li>
			<li className='navbar-mobile__item' onClick={props.btnClicked}>
				<NavLink className='navbar-mobile__link' to='about'>
					About
				</NavLink>
			</li>
			<li className='navbar-mobile__item'>
				<ProductConsumer>
					{value => {
						if (value.cart.length > 0) {
							return (
								<NavLink to='/cart' className='navbar-mobile__link'>
									Cart ({value.cart.length})
								</NavLink>
							);
						} else {
							return null;
						}
					}}
				</ProductConsumer>
			</li>
		</ul>
	</div>
);

export default NavbarMobileList;
