import React from 'react'
//import PayPalButton from './PayPalButton.js';
import PayPalButtonV2 from './PayPalButtonV2';

export default function CartTotals({value, history}) {
    const { 
        cartSubTotal, 
        // cartTax, 
        cartTotal, 
        clearCart, 
        cart 
    } = value;
    
    return (
        <div className="cart-total">
            <div className="cart-total__subtotal">
                <span className="paragraph--sm">Subtotal</span>
                <span className="paragraph--sm">${cartSubTotal}</span>
            </div>
            <div className="cart-total__shipping">
                <span className="paragraph--sm">Shipping</span>
                <span className="paragraph--sm">$3.98</span>
            </div>
            {/* this was the tax calculation */}
            {/* <div className="cart-total__tax">
                <span className="paragraph--sm">Sales Tax</span>
                <span className="paragraph--sm">${cartTax}</span>
            </div> */}
            <hr />
            <div className="cart-total__total">
                <span className="paragraph">Total</span>
                <span className="paragraph">${cartTotal}</span>
            </div>
            <div className="cart-total__paypal u-center-text">
                {/* <PayPalButton
                    total={cartTotal}
                    history={history}
                    clearCart={clearCart}
                /> */}
                <PayPalButtonV2
                    total={cartTotal}
                    history={history}
                    clearCart={clearCart}
                    cart={cart}
                />
            </div>
        </div>
    )
}
