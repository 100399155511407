import React from 'react';

const HowItStarted = () => (
	<div className='how-it-started'>
		<div className='how-it-started__img-box'></div>
		<div className='how-it-started__text-box'>
			<span className='heading-tertiary white grobold'>
				"It all started with a goal to make math fun..."
			</span>
			<p className='paragraph white'>
				My little sister had trouble with regular multiplication cards that her
				school provided. I knew I had to think of a better way to help her
				practice her math facts, and so I made a few cards on Google Slides and
				printed them one day after school. The goal was to find the match
				between the two cards. My sister loved it! As time went on, my sister
				and I kept improving the material on the cards, we created different
				types of games, and we created the cards so that any two random cards
				will have one and only one match between them. Once we showed them to
				our friends and family, they were an instant hit and we knew that we
				needed to sell them. My sister drew up a few turtles as our logo, and
				the rest is history!
			</p>
		</div>
	</div>
);

export default HowItStarted;
