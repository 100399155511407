import React from 'react';

export default function CartItem({ item, value }) {
	const { id, title, images, price, total, count } = item;
	const { incrementHandler, decrementHandler, removeItem } = value;

	return (
		<div className='cart-item'>
			<div className='cart-item__column--custom cart-item__column'>
				<img src={images[0]} alt={title} className='cart-item__column--img' />
				<div className='cart-item__column cart-item__column__btn-box'>
					<p className='paragraph u-block-el'>{title}</p>
					<div className='cart-item__column cart-item__column--mobile'>
						<span className='paragraph'>{price}</span>
					</div>
					<p
						className='paragraph u-underline u-block-el'
						onClick={() => removeItem(id)}
					>
						Remove
					</p>
				</div>
			</div>
			<div className='cart-item__column'>
				<span className='paragraph'>
                    <span className='cart-item__column--desktop'><span className="cart-item__column--mobile">Price: </span>{price}</span>
				</span>
			</div>
			<div className='cart-item__column'>
				<span className='cart-item__column--mobile paragraph'>Quantity: </span>
				<span className='quantity' onClick={() => decrementHandler(id)}>
					-
				</span>
				<span className='quantity'>{count}</span>
				<span className='quantity' onClick={() => incrementHandler(id)}>
					+
				</span>
			</div>
			<div className='cart-item__column'>
				<span className='paragraph'>
					<span className='cart-item__column--mobile'>Total: </span>
					{total}
				</span>
			</div>
		</div>
	);
}
